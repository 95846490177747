import React, { useState } from "react"
import imageUrlBuilder from "@sanity/image-url"
import { Flex, Box, Image, Label, Text, Input, Button } from "../system"
import { PaperPlaneIcon } from "@modulz/radix-icons"
import { client } from "../../sanity"
import { nanoid } from "nanoid"
import { useAuth0 } from "@auth0/auth0-react"

const builder = imageUrlBuilder(client)

function urlFor(source) {
  return builder.image(source)
}

const StageBlock = ({ u, id, index, number, stage }) => {
  const [comment, setComment] = useState("")
  const { user } = useAuth0()
  const { name } = user && user["https://ilana.uk/metadata"]

  console.log(stage)

  const addComment = () => {
    client
      .patch(id)
      // Ensure that the `reviews` arrays exists before attempting to add items to it
      .setIfMissing({ [`questions[${index}].uploads[${number}].comments`]: [] })
      .insert("after", `questions[${index}].uploads[${number}].comments[-1]`, [
        { text: comment, user: name },
      ])
      .commit({ autoGenerateArrayKeys: true })
      .then(() => {
        fetch("/.netlify/functions/sendComment", {
          method: "POST",
          body: JSON.stringify({
            name: name,
            comment: comment,
            stage: stage.stage,
            expert: stage.owner.assigned.email,
            designer: stage.owner.email,
            sender: user.email,
          }),
        }).catch(() => alert("Something went wrong, please try again later."))
      })
      .catch(err => console.log(err))
  }

  const hitEnter = e => {
    if (comment === "") {
      return
    }
    if (e === 13) {
      setComment("")
      addComment()
      // sendComment(data._id, comment)
    }
  }

  return (
    <Box>
      <Image src={urlFor(u.image).url()} />
      <Box mb={5}>
        {/* <Box maxHeight={200} overflowY="scroll"> */}
        <Box>
          {u.comments &&
            u.comments.map((c, key) => (
              <Box mb={3} key={key}>
                <Label color="secondary" fontSize={1} down>
                  {c.user}
                </Label>
                <Text textAlign="left">{c.text}</Text>
              </Box>
            ))}
        </Box>
        <Flex>
          <Input
            p={3}
            width="100%"
            type="text"
            placeholder="Leave comment..."
            value={comment}
            onChange={e => setComment(e.target.value)}
            onKeyDown={e => hitEnter(e.keyCode)}
          />
          <Button
            onClick={() => addComment()}
            bg="black"
            color="white"
            hbg="#ED6E49"
            hcolor="white"
            ml={3}
            p={3}
            width={60}
            minWidth={60}
            height={60}
            borderRadius={30}
          >
            <PaperPlaneIcon />
          </Button>
        </Flex>
      </Box>
    </Box>
  )
}

export default StageBlock
