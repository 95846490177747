import React, { useEffect, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import Layout from "../../components/Community/Layout"

import { client } from "../../sanity"
import { Flex, Title, Box, Label } from "../../components/system"

import StageBlock from "../../components/Community/stage-block"

const header = stage => {
  switch (stage) {
    case 1:
      return (
        <>
          <Label fontSize={1} color="faded">
            Fashion Sketch & CAD Design
          </Label>
          <Title fontSize={[6, 7]} color="secondary">
            Stage One
          </Title>
        </>
      )
      break
    case 2:
      return (
        <>
          <Label fontSize={1} color="faded">
            Toile & Pattern
          </Label>
          <Title fontSize={[6, 7]} color="secondary">
            Stage Two
          </Title>
        </>
      )
      break
    case 3:
      return (
        <>
          <Label fontSize={1} color="faded">
            Sample
          </Label>
          <Title fontSize={[6, 7]} color="secondary">
            Stage Three
          </Title>
        </>
      )
      break
    default:
  }
}

const ViewStage = ({ id }) => {
  const [stage, setStage] = useState()

  useEffect(() => {
    const query =
      '*[_type == "stage" && _id == $id]{_id,stage, owner->{name,email,assigned->{email}, avatar{asset->{url}}},questions[]{title,uploads[]{_id,image{asset->{url}},comments}}}'
    const params = { id: id }

    client.fetch(query, params).then(data => {
      setStage(data[0])
    })

    const subscription = client.listen(query, params).subscribe(update => {
      setStage(update.result)
    })

    return () => subscription.unsubscribe()
  }, [id])

  return (
    <Layout>
      <Flex flexDirection="column">
        <Flex flexDirection="column" mb={5}>
          {header(stage?.stage)}
        </Flex>
        <Flex flexDirection="column" textAlign="left">
          {stage &&
            stage.questions.map((q, key) => (
              <Flex
                key={key}
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                px={[3, 5, 7]}
                mb={6}
              >
                <Title textAlign="left" fontSize={[5, 6]}>
                  {q.title}
                </Title>
                {q.uploads.map((u, index) => (
                  <Box key={index}>
                    <StageBlock
                      u={u}
                      id={id}
                      index={key}
                      number={index}
                      stage={stage}
                    />
                  </Box>
                ))}
              </Flex>
            ))}
        </Flex>
      </Flex>
    </Layout>
  )
}

export default ViewStage
